import type { AppDocument } from "../types/app.type";

export const useApp = defineStore("app", {
    state: () => ({
        apps: [] as AppDocument[],
    }),
    actions: {
        async initApps(): Promise<AppDocument[]> {
            if (_IsEmpty(this.apps)) {
                const apps = await $axios.get("/function/queryFunctions").catch((err) => {
                    throw err;
                });
                this.apps = apps as any;
            }
            return this.apps;
        },
        refresh() {
            this.apps = [];
        },
        async hasFunction(path: string): Promise<boolean> {
            const apps = await this.initApps().catch((err) => {
                throw err;
            });
            return apps.some((app) => app.functions.some((func) => func.route === path));
        },
    },
});
